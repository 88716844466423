import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { CookiesProvider } from 'react-cookie'
import { RouterProvider } from 'react-router-dom'
import { Provider } from 'react-redux'

import { createStoreWithMiddlewares } from './Redux/store'
import reportWebVitals from './reportWebVitals'
import './Styles/global.scss'
import Router from './Router'
import LoadingPage from './Components/LoadingPage'
import { ToasterProvider } from './Contexts/Toaster'
import { EditModeProvider } from './Contexts/EditMode/EditMode'

const store = createStoreWithMiddlewares()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <CookiesProvider>
    <Suspense fallback={<LoadingPage />}>
      <Provider store={store}>
        <EditModeProvider>
          <ToasterProvider>
            <RouterProvider router={Router} />
          </ToasterProvider>
        </EditModeProvider>
      </Provider>
    </Suspense>
  </CookiesProvider>,
)

reportWebVitals()
