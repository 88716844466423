import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

import { ROLES } from '../Models/Role'
import { UserData } from '../Models/User'

interface IRGProps {
  onlyStudent: boolean
}

export const RoleGuard = ({ onlyStudent }: IRGProps) => {
  const user = useSelector((state: { user: UserData }) => state.user, shallowEqual)

  const validations = () => {
    if (onlyStudent && user.roles.some((role) => role._id === ROLES.Student)) return true

    if (!onlyStudent) return true

    return false
  }

  return validations() ? <Outlet /> : <Navigate replace to="/dashboard" />
}

export default RoleGuard
