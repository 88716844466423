/* eslint-disable react/require-default-props */
import React, { FunctionComponent } from 'react'

interface CloseProps {
  width?: string
  height?: string
  color?: string
}
const Close: FunctionComponent<CloseProps> = ({
  width = '20',
  height = '20',
  color = '#27272E',
}) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6L6 18"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M6 6L18 18"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default Close
