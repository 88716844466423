import jwtDecode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

import LoadingPage from '../Components/LoadingPage'
import { TokenState } from '../Models/Auth'
import { clearToken } from '../Redux/states/auth'
import { clearUser, setUser } from '../Redux/states/user'
import { getUserById } from '../Services/users'
// import { getRoles } from '../Services/roles'
// import { setRoles } from '../Redux/states/roles'
// import { ROLES } from '../Models/Role'
import { useToaster } from '../Contexts/Toaster'
import { ROLES } from '../Models/Role'

export const AuthGuard = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const { token } = useSelector((state: { auth: any }) => state.auth, shallowEqual)
  const { showToaster } = useToaster()
  const dispatch = useDispatch()

  const getDataUser = async (tokenToDecode: string) => {
    try {
      const dataToken: TokenState = jwtDecode(tokenToDecode)
      const resUser = await getUserById(dataToken.userId)
      const isStudent = resUser?.roles.find((role: any) => role._id === ROLES.Student)

      if (isStudent || (resUser?.roles.length && resUser.roles[0].allowedOperations.length))
        dispatch(setUser(resUser))
      else throw new Error('No tiene roles y/o operaciones')
    } catch (error) {
      dispatch(clearToken())
      dispatch(clearUser())
      showToaster({
        type: 'error',
        message: 'No tiene permisos para poder ingresar, consulte con soporte.',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (token) {
      getDataUser(token)
    } else {
      setLoading(false)
    }
  }, [])

  if (loading) return <LoadingPage />

  return token ? <Outlet /> : <Navigate replace to="/login" />
}

export default AuthGuard
