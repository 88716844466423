import { ForgotPasswordData } from '../Models/Login'
import { ActivedBodyUsers, UserData, UserParams } from '../Models/User'

import { API } from './api'

export const getUserById = (id: string) =>
  API.get(`/users/${id}`)
    .then((res) => res.data)
    .catch((err) => {
      throw err
    })

export const getUsers = async (params: UserParams) =>
  API.get('/users', { params })
    .then((res) => res.data)
    .catch((err) => {
      throw err.response
    })

export const createUser = async (dataNewUser: UserData) =>
  API.post('/users', dataNewUser)
    .then((res) => res.data)
    .catch((err) => {
      throw err.response
    })
export const registerUser = async (dataNewUser: UserData) =>
  API.post('/users/register-student', dataNewUser)
    .then((res) => res.data)
    .catch((err) => {
      throw err.response
    })

export const updateUser = (id: string, dataUser: UserData) =>
  API.patch(`/users/${id}`, dataUser)
    .then((res) => res.data)
    .catch((err) => {
      throw err.response
    })

export const deleteUserById = async (id: string) =>
  API.delete(`/users/${id}`)
    .then((res) => res.data)
    .catch((err) => {
      throw err.response
    })

export const activatedUsers = (dataUser: ActivedBodyUsers) =>
  API.patch('/users/activated', dataUser)
    .then((res) => res.data)
    .catch((err) => {
      throw err.response
    })

export const uploadImg = async (id: string, file: FormData) =>
  API.patch(`/users/${id}/picture`, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      throw err.response
    })

export const deleteImg = async (id: string) =>
  API.patch(`/users/${id}/picture`)
    .then((res) => res.data)
    .catch((err) => {
      throw err.response
    })

export const uploadFile = async (id: string, file: FormData) =>
  API.patch(`/users/${id}/curriculum-vitae`, file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      throw err.response
    })

export const deleteFile = async (id: string) =>
  API.patch(`/users/${id}/curriculum-vitae`)
    .then((res) => res.data)
    .catch((err) => {
      throw err.response
    })

export const changePassword = (dataUser: ForgotPasswordData) =>
  API.patch('/users/password', dataUser)
    .then((res) => res.data)
    .catch((err) => {
      throw err.response
    })
