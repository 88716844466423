import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UserData, UserDataInitial } from '../../Models/User'

export const userSlice = createSlice({
  name: 'User',
  initialState: UserDataInitial,
  reducers: {
    setUser: (state, action: PayloadAction<UserData>) => action.payload,
    clearUser: () => UserDataInitial,
  },
})

export const { setUser, clearUser } = userSlice.actions

export default userSlice.reducer
