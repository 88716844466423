import React, { useContext, useEffect, useState } from 'react'
import Toast from 'react-bootstrap/Toast'
import ToastContainerRB from 'react-bootstrap/ToastContainer'

import Check from '../../Components/icons/Check'
import Close from '../../Components/icons/Close'
import Info from '../../Components/icons/Info'
import './ToastCustom.scss'

interface IToasterContext {
  showToaster: (toaster: ToasterState) => void
}

interface ToasterState {
  type: 'default' | 'success' | 'warning' | 'error'
  message: string
  show?: boolean
  onClick?: () => void
}

export const ToasterContext = React.createContext<IToasterContext>({ showToaster: () => null })

export function ToasterProvider({ children }: any): JSX.Element {
  const [toast, setToast] = useState<ToasterState>({
    type: 'default',
    message: '',
    show: false,
  })

  const showToaster = (toaster: ToasterState) => {
    setToast({ ...toaster, show: true })
  }

  const closeToaster = () => {
    setToast({
      type: 'default',
      message: '',
      show: false,
    })
  }

  useEffect(() => {
    if (toast.show) {
      setTimeout(() => {
        closeToaster()
      }, 4000)
    }
  }, [toast])

  const icon = {
    success: <Check color="#27272E" height="9px" width="16px" />,
    warning: <Info color="#27272E" height="16px" width="16px" />,
    error: <Close color="#27272E" height="16px" width="16px" />,
    default: <Close color="#27272E" height="16px" width="16px" />,
  }

  return (
    <ToasterContext.Provider value={{ showToaster }}>
      {children}
      <ToastContainerRB style={{ margin: '24px', position: 'fixed', top: 0, right: 0 }}>
        <Toast
          className={`toast-custom toast-custom-background-${toast.type}`}
          show={toast.show}
          onClose={closeToaster}
        >
          <Toast.Header
            className={`toast-custom-header toast-header-background-${toast.type}`}
            closeButton={false}
          >
            <div className={`toast-custom-icon toast-icon-background-${toast.type}`}>
              {icon[toast.type]}
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: toast.message }}
              className="toast-custom-message"
              onClick={() =>
                toast.onClick
                  ? toast.onClick()
                  : () => {
                      return
                    }
              }
            />
            <button className="toast-custom-button-close" type="button" onClick={closeToaster}>
              <Close color="#27272E" height="16px" width="16px" />
            </button>
          </Toast.Header>
        </Toast>
      </ToastContainerRB>
    </ToasterContext.Provider>
  )
}

export function useToaster() {
  const { showToaster } = useContext(ToasterContext)

  return { showToaster }
}
