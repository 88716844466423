import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { usePermissions } from '../Hooks/usePermissions'

interface IPGProps {
  allowedOperation: string
}

export const PermissionGuard = ({ allowedOperation }: IPGProps) => {
  const permissions = usePermissions()

  return permissions.find((permission: string) => permission === allowedOperation) ? (
    <Outlet />
  ) : (
    <Navigate replace to="/dashboard" />
  )
}

export default PermissionGuard
