import axios, { RawAxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios'
import { Cookies } from 'react-cookie'

import { apiUrl } from './constants'

const API = axios.create({
  baseURL: apiUrl,
})

API.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const cookies = new Cookies()
    const token = cookies.get('accessToken')

    if (token) {
      config.headers = config.headers ?? {}
      ;(config.headers as RawAxiosRequestHeaders).Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => Promise.reject(error),
)

export { API }
