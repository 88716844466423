import React, { createContext, useContext, useState } from 'react'

interface EditModeContextType {
  editMode: boolean
  setEditMode: (value: boolean) => void
}

const EditModeContext = createContext<EditModeContextType | undefined>(undefined)

export function EditModeProvider({ children }: any) {
  const [editMode, setEditMode] = useState(false)

  return (
    <EditModeContext.Provider value={{ editMode, setEditMode }}>
      {children}
    </EditModeContext.Provider>
  )
}

export function useEditMode() {
  const context = useContext(EditModeContext)

  if (!context) {
    throw new Error('useEditMode must be used within an EditModeProvider')
  }

  return context
}
