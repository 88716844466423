/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React, { FC, useState } from 'react'

interface CheckProps {
  width?: string
  height?: string
  color?: string
  hover?: string
}

const Check: FC<CheckProps> = ({
  width = '9',
  height = '7',
  color = '#27272E',
  hover = '#27272E',
}) => {
  const [hoverState, setHoverState] = useState(color)

  const handleHover = () => {
    setHoverState((prev) => {
      if (prev === color) return hover

      return color
    })
  }

  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 9 7"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleHover}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      <path
        clipRule="evenodd"
        d="M6.86612 0.21967C7.15901 -0.0732232 7.63388 -0.0732232 7.92678 0.21967C8.21608 0.508971 8.21963 0.975816 7.93742 1.26947L3.94522 6.25973C3.93946 6.26693 3.9333 6.27381 3.92678 6.28033C3.63388 6.57322 3.15901 6.57322 2.86612 6.28033L0.21967 3.63388C-0.0732233 3.34099 -0.0732233 2.86612 0.21967 2.57322C0.512563 2.28033 0.987437 2.28033 1.28033 2.57322L3.37385 4.66674L6.84622 0.242105C6.8524 0.234235 6.85904 0.226743 6.86612 0.21967Z"
        fill={hoverState}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Check
