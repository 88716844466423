import { configureStore, combineReducers, EnhancedStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import authReducer from './states/auth'
import userReducer from './states/user'
import rolesReducer from './states/roles'

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  roles: rolesReducer,
})

export const createStoreWithMiddlewares = (initialState = {}): EnhancedStore => {
  const sagaMiddleware = createSagaMiddleware()

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
      }).concat(sagaMiddleware),
    preloadedState: initialState,
    devTools: true,
  })

  return store
}
