import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'

import LoadingPage from '../Components/LoadingPage'
import { Category, OPERATIONS } from '../Models/Operations'

import AuthGuard from './AuthGuard'
import RoleGuard from './RoleGuard'
import PermissionGuard from './PermissionGuard'

const NotFound = lazy(() => import('../Pages/NotFound'))
const Dashboard = lazy(() => import('../Pages/Dashboard'))
const DetailRol = lazy(() => import('../Pages/DetailRol'))
const ForgotPassword = lazy(() => import('../Pages/ForgotPassword'))
const Home = lazy(() => import('../Pages/Home'))
const Login = lazy(() => import('../Pages/Login'))
const SignUp = lazy(() => import('../Pages/SignUp'))
const People = lazy(() => import('../Pages/People'))
const Roles = lazy(() => import('../Pages/Roles'))
const Staff = lazy(() => import('../Pages/Staff'))
const DetailStaff = lazy(() => import('../Pages/DetailStaff'))
const Programs = lazy(() => import('../Pages/Programs'))
const Synchronous = lazy(() => import('../Pages/Synchronous'))
const SyncProgramDetails = lazy(() => import('../Pages/SyncProgramDetails'))
const GroupDetails = lazy(() => import('../Pages/GroupDetails'))
const SyncCoursesDetails = lazy(() => import('../Pages/SyncCoursesDetails'))
const ActivityDetail = lazy(() => import('../Pages/ActivityDetail'))
const ActivitySolution = lazy(() => import('../Pages/ActivitySolution'))
const BlockDetail = lazy(() => import('../Pages/BlockDetail'))
const CalendarPage = lazy(() => import('../Pages/CalendarPage'))
const MyProfile = lazy(() => import('../Pages/MyProfile'))

const Group = lazy(() => import('../Pages/StudentPages/Group'))
const Course = lazy(() => import('../Pages/StudentPages/Course'))
const Activity = lazy(() => import('../Pages/StudentPages/Activity'))
const StudentCatalogue = lazy(() => import('../Pages/StudentPages/StudentCatalogue'))
const StudentCalendar = lazy(() => import('../Pages/StudentPages/StudentCalendar'))
const Payment = lazy(() => import('../Pages/Payment'))
const Router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <NotFound />,
  },
  {
    path: '/loading',
    element: <LoadingPage />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: '/login',
    element: <Login />,
    children: [
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
    ],
  },
  {
    path: '/signup',
    element: <SignUp />,
  },
  {
    element: <AuthGuard />,
    children: [
      {
        path: '/dashboard',
        element: <Dashboard />,
        children: [
          {
            element: <RoleGuard onlyStudent={false} />,
            children: [
              {
                path: 'people',
                element: <People />,
                children: [
                  {
                    element: <PermissionGuard allowedOperation={OPERATIONS[Category.Roles].List} />,
                    children: [
                      { path: 'roles', element: <Roles /> },
                      {
                        element: (
                          <PermissionGuard allowedOperation={OPERATIONS[Category.Roles].View} />
                        ),
                        children: [{ path: 'roles/:rolId', element: <DetailRol /> }],
                      },
                    ],
                  },
                  {
                    element: <PermissionGuard allowedOperation={OPERATIONS[Category.Staff].List} />,
                    children: [
                      { path: 'ibaktor', element: <Staff /> },
                      {
                        element: (
                          <PermissionGuard allowedOperation={OPERATIONS[Category.Staff].View} />
                        ),
                        children: [{ path: 'ibaktor/:userId', element: <DetailStaff /> }],
                      },
                    ],
                  },
                  {
                    element: (
                      <PermissionGuard allowedOperation={OPERATIONS[Category.Student].List} />
                    ),
                    children: [
                      { path: 'students', element: <Staff /> },
                      {
                        element: (
                          <PermissionGuard allowedOperation={OPERATIONS[Category.Student].View} />
                        ),
                        children: [{ path: 'students/:userId', element: <DetailStaff /> }],
                      },
                    ],
                  },
                  {
                    element: (
                      <PermissionGuard allowedOperation={OPERATIONS[Category.Professor].List} />
                    ),
                    children: [
                      { path: 'professor', element: <Staff /> },
                      {
                        element: (
                          <PermissionGuard allowedOperation={OPERATIONS[Category.Professor].View} />
                        ),
                        children: [{ path: 'professor/:userId', element: <DetailStaff /> }],
                      },
                    ],
                  },
                ],
              },
              {
                path: 'content',
                element: <Programs />,
                children: [
                  {
                    element: (
                      <PermissionGuard allowedOperation={OPERATIONS[Category.Programs].List} />
                    ),
                    children: [
                      { path: 'programs-sync', element: <Synchronous /> },
                      {
                        element: (
                          <PermissionGuard allowedOperation={OPERATIONS[Category.Programs].View} />
                        ),
                        children: [
                          { path: 'programs-sync/:programId', element: <SyncProgramDetails /> },
                        ],
                      },
                    ],
                  },
                  {
                    element: (
                      <PermissionGuard allowedOperation={OPERATIONS[Category.Groups].List} />
                    ),
                    children: [
                      { path: 'groups', element: <Synchronous /> },
                      {
                        element: (
                          <PermissionGuard allowedOperation={OPERATIONS[Category.Groups].View} />
                        ),
                        children: [{ path: 'groups/:groupId', element: <GroupDetails /> }],
                      },
                    ],
                  },
                  {
                    element: (
                      <PermissionGuard allowedOperation={OPERATIONS[Category.Courses].List} />
                    ),
                    children: [
                      { path: 'courses-sync', element: <Synchronous /> },
                      {
                        element: (
                          <PermissionGuard allowedOperation={OPERATIONS[Category.Courses].View} />
                        ),
                        children: [
                          { path: 'courses-sync/:courseId', element: <SyncCoursesDetails /> },
                        ],
                      },
                      {
                        element: (
                          <PermissionGuard allowedOperation={OPERATIONS[Category.Blocks].View} />
                        ),
                        children: [
                          { path: 'courses-sync/:courseId/:blockId', element: <BlockDetail /> },
                        ],
                      },
                      {
                        path: 'courses-sync/:courseId/:blockId/:activityId',
                        element: <ActivityDetail />,
                      },
                      {
                        path: 'courses-sync/:courseId/:blockId/:activityId/:studentId',
                        element: <ActivitySolution />,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'calendar',
                element: <CalendarPage />,
              },
              {
                path: 'my-profile',
                element: <MyProfile />,
              },
              {
                path: 'pagos',
                element: <Payment />,
              },
            ],
          },
          {
            element: <RoleGuard onlyStudent />,
            children: [
              {
                path: 'my-calendar',
                element: <StudentCalendar />,
              },
              {
                path: 'catalogue',
                element: <StudentCatalogue />,
              },
              { path: 'program/:idGroup', element: <Group /> },
              { path: 'course/:idCourse', element: <Course /> },
              { path: 'course/:idCourse/:idActivity', element: <Activity /> },
              {
                path: 'my-profile',
                element: <MyProfile />,
              },
            ],
          },
        ],
      },
    ],
  },
])

export default Router
