import React, { FC } from 'react'
import './IsoLogo.scss'

interface IsoLogoProps {
  size: number
  animation: boolean
}

const IsoLogo: FC<IsoLogoProps> = ({ size, animation }) => (
  <div className="circle-white" style={{ width: `${size}px`, height: `${size}px` }}>
    <div
      className={`circle-orange ${animation && 'animation'}`}
      style={{ width: `${size / 1.15}px`, height: `${size / 1.15}px` }}
    >
      <div
        className={`triangle ${animation && 'animation'}`}
        style={{
          borderTopWidth: `${size / 2}px`,
          borderLeftWidth: `${size / 4}px`,
          borderRightWidth: `${size / 4}px`,
        }}
      />
    </div>
  </div>
)

export default IsoLogo
