import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Cookies } from 'react-cookie'

import { AuthState, EmptyAuthState } from '../../Models/Auth'

const InitializeAuthState = (): AuthState => {
  const cookies = new Cookies()
  const token = cookies.get('accessToken')

  return { token } || EmptyAuthState
}

export const authSlice = createSlice({
  name: 'Auth',
  initialState: InitializeAuthState(),
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      const accessToken = action.payload
      const cookies = new Cookies()

      cookies.set('accessToken', accessToken, { path: '/' })
      state.token = accessToken
    },
    clearToken: (state) => {
      const cookies = new Cookies()

      cookies.remove('accessToken')
      state.token = ''
    },
  },
})

export const { setToken, clearToken } = authSlice.actions

export default authSlice.reducer
