export interface TokenState {
  userId: string
}

export interface AuthState {
  token: string
}

export const EmptyAuthState: AuthState = {
  token: '',
}
