export type OperationData = {
  _id: string
  name: string
}

export type CategoryOperationData = {
  _id: string
  name: Category
  operations: OperationData[]
}

export enum Category {
  Roles = 'Roles',
  Staff = 'Ibaktor Staff',
  Professor = 'Profesores',
  Student = 'Alumnos',
  Programs = 'Programas sincrónicos',
  Groups = 'Grupos sincrónicos',
  Courses = 'Cursos sincrónicos',
  Blocks = 'Bloques de curso sincrónico',
  Activities = 'Actividades sincrónicas',
}

export const OPERATIONS = {
  [Category.Roles]: {
    Create: '644a94084708d25ca56387d0',
    Edit: '644a94084708d25ca56387d1',
    Delete: '644a94084708d25ca56387d4',
    List: '644a94084708d25ca56387d2',
    View: '644a94084708d25ca56387d3',
  },
  [Category.Staff]: {
    Create: '644a94084708d25ca56387d5',
    Edit: '644a94084708d25ca56387d6',
    Delete: '644a94084708d25ca56387d9',
    View: '644a94084708d25ca56387d8',
    List: '644a94084708d25ca56387d7',
  },
  [Category.Professor]: {
    Create: '644a94084708d25ca56387a0',
    Edit: '644a94084708d25ca56387a1',
    Delete: '644a94084708d25ca56387a4',
    View: '644a94084708d25ca56387a3',
    List: '644a94084708d25ca56387a2',
  },
  [Category.Student]: {
    Create: '644a94084708d25ca56387a5',
    Edit: '644a94084708d25ca56387a6',
    Delete: '644a94084708d25ca56387a9',
    View: '644a94084708d25ca56387a8',
    List: '644a94084708d25ca56387a7',
  },
  [Category.Programs]: {
    Create: '644a94084708d25ca56387b1',
    Edit: '644a94084708d25ca56387b2',
    Delete: '644a94084708d25ca56387b5',
    View: '644a94084708d25ca56387b4',
    List: '644a94084708d25ca56387b3',
  },
  [Category.Groups]: {
    Create: '644a94084708d25ca56387b6',
    Edit: '644a94084708d25ca56387b7',
    Delete: '644a94084708d25ca56387b0',
    View: '644a94084708d25ca56387b9',
    List: '644a94084708d25ca56387b8',
  },
  [Category.Courses]: {
    Create: '644a94084708d25ca56387c6',
    Edit: '644a94084708d25ca56387c7',
    Delete: '644a94084708d25ca56387c0',
    View: '644a94084708d25ca56387c9',
    List: '644a94084708d25ca56387c8',
    Payment: '670d1daeb3a75710d4974e08',
  },
  [Category.Blocks]: {
    Create: '644a94084708d25ca56387c1',
    Edit: '644a94084708d25ca56387c2',
    Delete: '644a94084708d25ca56387c5',
    View: '644a94084708d25ca56387c4',
    List: '644a94084708d25ca56387c3',
  },
  [Category.Activities]: {
    Create: '644a94084708d25ca56387e0',
    Edit: '644a94084708d25ca56387e1',
    Delete: '644a94084708d25ca56387e3',
    View: '644a94084708d25ca56387e4',
    List: '644a94084708d25ca56387e2',
    RateTask: '644a94084708d25ca56387e4',
    DownloadSurvey: '6709585c771aa38be5405e9a',
  },
}
