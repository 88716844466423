import React from 'react'

import IsoLogo from '../IsoLogo'
import './LoadingPage.scss'

const LoadingPage = () => (
  <div className="loading-page-container">
    <IsoLogo animation size={74} />
    <h1>Ibaktor</h1>
  </div>
)

export default LoadingPage
