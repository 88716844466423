import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Role } from '../../Models/Role'

export const rolesSlice = createSlice({
  name: 'Roles',
  initialState: Array<Role>,
  reducers: {
    setRoles: (state, action: PayloadAction<Array<Role>>) => action.payload,
    clearRoles: () => [],
  },
})

export const { setRoles, clearRoles } = rolesSlice.actions

export default rolesSlice.reducer
