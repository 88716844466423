import { shallowEqual, useSelector } from 'react-redux'

import { Role } from '../Models/Role'
import { UserData } from '../Models/User'

export const usePermissions = (): string[] => {
  const { roles } = useSelector((state: { user: UserData }) => state.user, shallowEqual)
  const allowedOperationIds = roles
    .flatMap((role: Role) => role.allowedOperations)
    .map((operation) => operation._id)

  return allowedOperationIds
}
