import { OperationData } from './Operations'
import { OrderTableBy } from './Tables'

export interface Role {
  _id: string
  name: string
  createdBy: string
  createdAt: string
  updatedAt?: string
  updatedBy?: string
  lastAccess?: string
  allowedOperations: OperationData[]
}

export interface UpdateRole {
  name: string
  allowedOperationIds: string[]
}

export interface ParamsRoles {
  page: number
  limit: number
  sortProperty?: string
  sortOrder?: OrderTableBy
}

export const EmptyRole: Role = {
  _id: '',
  name: '',
  createdBy: '',
  createdAt: '',
  updatedAt: '',
  updatedBy: '',
  lastAccess: '',
  allowedOperations: [],
}

export const ROLES = {
  Student: '644a926b4708d25ca56387cc',
  Admin: '644a926b4708d25ca56387ca',
  Professor: '644a926b4708d25ca56387cb',
}
